import templateUrl from './submission-meta-modal.html'
import './submission-meta-modal.scss'

const ProjectSubmissionMetaModalComponent = {
  bindings: {
    project: '<'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ProjectSubmissionMetaModal {
    constructor (UserService) {
      'ngInject'
      this._identify = 'ProjectSubmissionMetaModalComponent'
      this.UserService = UserService
      this.categoriesTaxonomy = [
        {
          name: 'Site Build',
          value: 'site',
          mappings: { type: 'design_and_develop', subject: 'site' },
          description: 'Build a new site',
        },
        {
          name: 'Customization',
          value: 'customization',
          mappings: { type: 'customize', subject: 'site' },
          description: 'Customize an existing site',
        },
        {
          name: 'E-commerce',
          value: 'e-commerce',
          mappings: { type: 'design_and_develop', subject: 'ecommerce_site' },
          description: 'Start or grow a business',
        },
        {
          name: 'Troubleshoot',
          value: 'troubleshoot-fix',
          mappings: { type: 'fix', subject: 'site' },
          description: 'Fix an issue',
        },
        {
          name: 'Design',
          value: 'design',
          mappings: { type: 'build_design', subject: 'build_website' },
          description: 'Create a great look',
        },
        {
          name: 'Optimization',
          value: 'seo-performance',
          mappings: { type: 'site_optimizations', subject: 'website_optimization' },
          description: 'Improve rankings and speed',
        },
        {
          name: 'Consultation',
          value: 'consultation',
          mappings: { type: 'consultation', subject: 'consultation' },
          description: 'Get expert advice',
        },
        {
          name: 'Retainer',
          value: 'maintenance-ongoing',
          mappings: { type: 'retainer', subject: 'retainer' },
          description: 'Maintain, support and grow',
        },
        {
          name: 'Other',
          value: 'other',
          mappings: { type: 'other', subject: 'other' },
          description: 'Custom request',
        },
      ]
    }
    getCategoryConfig (catKey) {
      return this.categoriesTaxonomy.find(cat => cat.value === catKey)
    }
    getCategoryName (catKey) {
      return this.getCategoryConfig(catKey).name
    }
  }
}

export default ProjectSubmissionMetaModalComponent
