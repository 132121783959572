import templateUrl from './error-message.html'
import './error-message.scss'

const ErrorMessageComponent = {
  bindings: {
    error: `<`,
    customMessages: '<messages',
    customOptions: '<options'
  },
  transclude: true,
  templateUrl,
  controller: class ErrorMessageComponent {
    constructor ($attrs, $element) {
      'ngInject'
      this._identify = 'ErrorMessageComponent'
      this.$attrs = $attrs
      this.$element = $element

      this.defaultMessages = {
        401: 'Not allowed',
        403: 'Not allowed',
        404: 'Not found',
        422: 'Something went wrong. Please, contact Codeable support.',
        'default': 'Something went wrong. Please, contact Codeable support.',
        'validation_failed': 'Validation error. Please, check the form and try again.',
        'rate_limit_reached': 'Too many requests. Please, try again in a few minutes.',

        // FE errors
        'PreferredExpertNotFoundError': 'Preferred expert(s) not found.'
      }

      this.defaultOptions = {
        noStyles: false,
        showServerMessage: false, // if server returns error with message display this message
        // TODO:
        // mapByServerError: false, // if true server error message will be remapped with FE defined messages
        // expire: false, // if true expire in 20s, if number expire in Xs ... expire = nullify error
        // allowSupport: false // if true show button to open support window
      }

      this.errorMessage = null
    }

    get message () {
      this.processError()
      return this.errorMessage
    }

    $onInit () {
      this.options = Object.assign(this.defaultOptions, this.customOptions)
      if ('showServerMessage' in this.$attrs) {
        this.options.showServerMessage = true
      }
      if ('noStyles' in this.$attrs) {
        this.options.noStyles = true
      }
      this.messages = Object.assign(this.defaultMessages, this.customMessages)

      // this.processError()
    }

    $onChanges (changes) {
      if (changes.error.currentValue) {
        this.$element[0].classList.remove('ng-hide')
      } else {
        this.$element[0].classList.add('ng-hide')
      }
    }

    processError () {
      this.errorMessage = null
      // console.log('[ErrorMessageComponent] > processError >', this.error.name)

      if (typeof this.error === 'string' && this.messages[this.error]) {
        // assign FE message if error id is provided directly
        this.errorMessage = this.messages[this.error]
      } else if (this.error?.message && this.messages[this.error?.message]) {
        // assign FE message if error id is provided via FE error ie throw new Error('default')
        this.errorMessage = this.messages[this.error.message]
      } else if (this.error?.name && this.messages[this.error?.name]) {
        // assign FE message if error id is provided via FE class error ie throw new MissignArgumentsError()
        this.errorMessage = this.messages[this.error.name]
      } else if (this.error?.data) {
        // assign FE message based on error name if exists in error object ( BE single error structure)
        // if error has data attribute it is most likely a server error
        const errorName = this.error?.data?.error
        if (typeof errorName === 'string' && this.messages[errorName]) {
          this.errorMessage = this.messages[errorName]
        }
        // assign FE message based on error name if exists in error object ( BE multiple error structure)
        const errors = this.error?.data?.errors
        if (Array.isArray(errors) && errors.length && this.messages[errors[0]?.error]) {
          this.errorMessage = this.messages[errors[0]?.error]
        }
      }

      // Find server error message if exists in error object
      if ((this.options.showServerMessage || !this.errorMessage) &&
          this.error &&
          this.error.data &&
          ((typeof this.error.data.error === 'string' && this.error.data.error !== '') ||
           (typeof this.error.data.message === 'string' && this.error.data.message !== ''))) {
        this.errorMessage = this.error.data.error ? this.error.data.error : this.error.data.message
      }

      // Fallback for old BE error object
      if ((this.options.showServerMessage || !this.errorMessage) &&
          this.error &&
          this.error.data &&
          !this.error.data.error &&
          Array.isArray(this.error.data.errors) &&
          this.error.data.errors.length) {
        const field = this.error.data.errors[0]?.field
        const message = this.error.data.errors[0]?.message
        this.errorMessage = [field, message].join(' ').trim().capitaliseFirstLetter()
      }

      // Fallback if no error messages found
      if (!this.errorMessage) {
        // assign FE message based on error status code or a default message as a fallback
        this.errorMessage = this.error?.status && this.messages[this.error.status] ? this.messages[this.error.status] : this.messages.default
      }
    }
  }
}

export default ErrorMessageComponent

// USE EXAMPLE
// <cdbl-error-message error="$ctrl.requestError" messages="$ctrl.errorMessages" options="{ showServerMessage: true }">Custom error message here will override default messages - usefull for some scenarios where one message suffice for all errors</cdbl-error-message>
