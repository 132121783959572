import jsonInflector from 'json-inflector'

import { mergeObjects } from '../../common/helpers/mergeObjects'
import { presetConfig, presetFields, presetResources } from './project-presets.config'

import PreferredExpertNotFoundError from '../../common/errors/types/preferred-expert-not-found.class'

const ProjectPresetsService = class ProjectPresetsService {
  constructor ($http, Configuration, $timeout, $q, NavigationService, AuthService, UserService, SegmentAnalytics, ModalService, UtilService, StorageService) {
    'ngInject'
    this._identify = 'ProjectPresetsService'
    this.$http = $http
    this.Configuration = Configuration
    this.$timeout = $timeout
    this.$q = $q
    this.NavigationService = NavigationService
    this.AuthService = AuthService
    this.UserService = UserService
    this.StorageService = StorageService
    this.SegmentAnalytics = SegmentAnalytics
    this.ModalService = ModalService
    this.UtilService = UtilService
  }

  extractPresetFieldValues (preset, filterFields = []) {
    const data = {}
    const fields = preset?.fields || {}
    const filterByFields = filterFields.length > 0
    for (const key in fields) {
      if (filterByFields) {
        if (filterFields.includes(key)) {
          data[key] = fields[key].value
        }
      } else {
        data[key] = fields[key].value
      }
    }

    // prepend titlePrefix to title
    if (data['title'] && fields['titlePrefix']?.value) {
      data['title'] = `${fields['titlePrefix'].value} ${data['title']}`
    }

    // concat description and description notes
    if (data['description'] && fields['descriptionNotes']?.value) {
      data['description'] = `${data['description']} \r\n\r\n---\r\n\r\n ${fields['descriptionNotes'].value}`
    }
    // if (data['preferredExperts']) {
    //   data['preferredExperts'] = data['preferredExperts'].map(expert => expert.id) // TODO decide if storing expert as object or only id
    // }
    if (data['preferredExpertsPublishForAll']) {
      data['preferredExpertsPublishForAll'] = Boolean(fields['preferredExperts']?.value?.length > 0) && data['preferredExpertsPublishForAll']
    }

    return data
  }
  trackEventParentPresetsListLoaded (preset) {
    const payload = {
      definerType: preset?.fields?.definerType?.value,
      definer: preset?.fields?.definer?.value
    }
    this.SegmentAnalytics.trackEvent('Parent Preset Listing Loaded', payload)
  }
  trackEventBasePresetFormLoaded (preset) {
    const payload = {
      definerType: preset.fields.definerType.value,
      definer: preset.fields.definer.value,
      form: preset.fields.form.value,
    }
    this.SegmentAnalytics.trackEvent('Parent Preset Form Loaded', payload)
  }
  trackEventPresetLinkCreated (preset) {
    const payload = {
      definerType: preset.fields.definerType.value,
      definer: preset.fields.definer.value,
      form: preset.fields.form.value,
    }

    this.SegmentAnalytics.trackEvent('Preset Link Created', payload)
  }
  trackEventPresetLoaded (preset) {
    const payload = {
      definerType: preset.fields.definerType.value,
      definer: preset.fields.definer.value,
      form: preset.fields.form.value,
    }

    this.SegmentAnalytics.trackEvent('Preset Loaded', payload)
  }

  async publishProjectWithPreset (preset = null, authData = null, presetToken = null) {
    const deferred = this.$q.defer()
    if (!preset || !presetToken) {
      deferred.reject(new Error('PublishProjectWithPreset is missing preset arguments'))
    }

    // 1. check if user is logged
    let error = null
    const loggedIn = this.AuthService.isAuthenticated()
    if (!loggedIn) {
      if (!authData) {
        deferred.reject(new Error('ProjectPresetsService is missing user credentials data'))
      }
      if (authData.authMode === 'register') {
        console.log('REGISTERING NEW USER...')
        await this.AuthService.register(authData)
          .then(user => {
            this.SegmentAnalytics.trackAccountCreated(user)
          })
          .catch(err => {
            error = err
            deferred.reject(err)
          })
      } else {
        console.log('LOGGING IN... ')
        await this.AuthService.login(authData)
          .catch(err => {
            console.log('LOGGING IN... err')
            error = err
            deferred.reject(err)
          })
      }
    } else {
      console.log('USER ALREADY LOGGED IN...')
    }


    if (!error) {
      await this.createAndPublishProject(preset, presetToken)
        .then(project => {
          console.log('[publishProjectWithPreset] > createAndPublishProject > response', project)
          deferred.resolve(project)

          this.SegmentAnalytics.trackTaskPosted(project, {
            definer: preset?.fields?.definer?.value,
            form: preset?.fields?.form?.value
          })
        })
        .catch(err => {
          console.log('[publishProjectWithPreset] > createAndPublishProject > err', err)
          error = err
          deferred.reject(err)
        })
    }
    return deferred.promise
  }

  updateClientProjectCount () {
    // so that user sees the submenu and potential first project info page
    const oldTasksCount = this.UserService.user.tasksCount ? this.UserService.user.tasksCount : 0
    this.UserService.updateUser({
      tasksCount: oldTasksCount + 1
    })
  }

  async createAndPublishProject (preset = null, presetToken = null) {
    const deferred = this.$q.defer()

    if (!preset || !presetToken) {
      deferred.reject(new Error('CreateAndPublishProject is missing arguments'))
    }

    const fields = this.extractPresetFieldValues(preset, [
      'title',
      'description',
      'websiteUrl',
      'projectType',
      'projectSubject',
      'urgency',
      'complexity',
      'preferredExperts',
      'preferredExpertsPublishForAll',
    ])

    const { preferredExperts, preferredExpertsPublishForAll, ...projectFields } = fields

    const payload = {
      ...projectFields,
      preferredContractors: preferredExperts, // renamed field for BE legacy project structure
      staysPreferred: !preferredExpertsPublishForAll, // renamed field for BE legacy project structure
      submissionFormVersion: '2.0', // TODO: next iteration rename to "version" (project version)
      projectPresetToken: presetToken // TODO: TBD base ? preset that was to publish
    }

    console.log('POSTING PROJECT...')
    let project = null
    await this.$http
      .post(`${this.Configuration.apiUrl}/tasks`, payload)
      .then(response => {
        project = response.data
      })
      .catch(err => {
        deferred.reject(err)
      })

    if (project) {
      console.log('PUBLISHING PROJECT...')
      await this.$http
        .put(`${this.Configuration.apiUrl}/tasks/${project?.id}`, project)
        .then(response => {
          this.updateClientProjectCount()
          deferred.resolve(response.data)
        })
        .catch(err => {
          deferred.reject(err)
        })
    }

    return deferred.promise
  }

  createPresetLink (preset) {
    console.log('CREATING PRESET SHARABLE LINK...')
    const { id, resources, ...rest } = preset
    const payload = {
      ...rest,
      shortcode: rest.fields.shortcode.value,
      multiUseLink: rest.fields.multiUseLink.value,
      expireDurationDays: rest.fields.multiUseLink.value ? 0 : rest.fields.expireDurationDays.value,
      parentId: id,
      internalTrackingId: rest.fields.internalTrackingId.value
      // definerType, definer, form are inherited from parent form
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/project_presets`, payload)
      .then(response => `${this.NavigationService.appUrl}/presets/apply?token=${response.data.token}`)
  }

  async loadPreset ($stateParams) {
    const deferred = this.$q.defer()
    console.log('[ProjectPresetsService] > loadPreset > token', $stateParams.token)

    let error
    let preset
    await this.$http
      .get(`${this.Configuration.apiUrl}/project_presets/${$stateParams.token}`, {
        headers: {
          'AJS-Anonymous-ID': this.StorageService.nativeGet('ajs_anonymous_id'),
          'User-ID': this.UserService.user ? this.UserService.user.id : undefined,
          'CDBL-Anonymous-ID': this.StorageService.get('cdbl_anonymous_id'),
        }
      })
      .then(response => {
        preset = response.data
      })
      .catch(err => {
        error = err
        deferred.reject(err)
      })

    if (!error) {
      await this.loadAllDatasets(preset.resources)
        .then(response => {
          preset.resources = mergeObjects(preset.resources, response)
          this.verifyPreferredExperts(preset)
          deferred.resolve(preset)
        })
        .catch(err => {
          deferred.reject(err)
        })
    }

    return deferred.promise
  }

  loadParentPresetsList ($stateParams = {}) {
    const params = {
      definer_type: $stateParams.partner ? 'partner' : $stateParams.expert ? 'expert' : $stateParams.staff ? 'staff' : '',
      definer: $stateParams.partner || $stateParams.expert || $stateParams.staff || '',
    }
    console.log('[ProjectPresetsService] > loadParentPresetsList > params', params)
    return this.$http
      .get(`${this.Configuration.apiUrl}/project_parent_presets/`, { params })
      .then(response => response.data)
  }


  async loadParentPreset ($stateParams) {
    const deferred = this.$q.defer()
    let error
    let preset
    let params = {
      definer_type: $stateParams.partner ? 'partner' : $stateParams.expert ? 'expert' : $stateParams.staff ? 'staff' : '',
      definer: $stateParams.partner || $stateParams.expert || $stateParams.staff || '',
      form: $stateParams.form || ''
    }
    // Clean up definer field if it has no value
    if (params.definer === 'true') {
      params.definer = ''
    }

    // For parent preset view/edit functionality use ids and not params and also use plural
    const byId = Boolean($stateParams.id)
    const urlSlug = byId ? `project_parent_presets/${$stateParams.id}` : 'project_parent_preset'
    if (byId) {
      params = {}
    }
    const isCreateNew = $stateParams.id === 'create'
    // ---

    if (isCreateNew) {
      // Load default preset from FE
      preset = this.defaultPreset
      preset.resources = presetResources
    } else {
      // Load preset from BE
      await this.$http
        .get(`${this.Configuration.apiUrl}/${urlSlug}`, { params })
        .then(response => {
          preset = this.processParentPreset(response.data) // response.data
        })
        .catch(err => {
          error = err
          deferred.reject(err)
        })
    }

    if (!error) {
      await this.loadAllDatasets(preset.resources)
        .then(response => {
          // return this.processPreset(response.data)
          preset.resources = mergeObjects(preset.resources, response)
          this.verifyPreferredExperts(preset)
          deferred.resolve(preset)
        })
        .catch(err => {
          deferred.reject(err)
        })
    }

    return deferred.promise
  }

  prepareParentPresetPayload (preset) {
    const { resources, ...restPreset } = preset
    const fields = this.extractPresetFieldValues(preset, [
      'form',
      'definerType',
      'definer',
      'shortcode',
      'expertPodId',
      'multiUseLink',
      'expireDurationDays'
    ])
    const { expireDurationDays, ...restFields } = fields

    const payload = {
      ...restPreset,
      ...restFields,
      expireDurationDays: fields.multiUseLink ? 0 : fields.expireDurationDays,
    }

    return payload
  }
  createParentPreset (preset) {
    const payload = this.prepareParentPresetPayload(preset)

    return this.$http
      .post(`${this.Configuration.apiUrl}/project_parent_presets`, payload)
      .then(response => response.data)
  }

  updateParentPreset (preset) {
    const payload = this.prepareParentPresetPayload(preset)

    return this.$http
      .put(`${this.Configuration.apiUrl}/project_parent_presets/${preset.id}`, payload)
      .then(response => response.data)
  }

  verifyPreferredExperts (preset) {
    console.log('verifyPreferredExperts', preset?.fields?.preferredExperts)
    const experts = preset?.fields?.preferredExperts?.value
    if (experts && experts?.length > 0) {
      experts.forEach(expertId => {
        const found = preset.resources.taskExpertsPreferrables.data.find(de => de.id === expertId)
        if (!found) {
          throw new PreferredExpertNotFoundError()
        }
        console.log('verifyPreferredExperts > found', found)
      })
    }
  }

  processParentPreset (rawPreset) {
    // const processedPreset = structuredClone(mergeObjects(this.defaultPreset, rawPreset))
    const processedPreset = structuredClone(rawPreset)
    if (processedPreset.config.version) {
      if (processedPreset.config.version < 'v1.1') {
        // Add pods custom field
        processedPreset.config.version = 'v1.1'
        processedPreset.expertPodId = null
        processedPreset.fields.expertPodId = this.defaultPreset.fields.expertPodId
        console.log('🚀 ~ ProjectPresetsService ~ processPreset ~ migration to v1.1', processedPreset)
      }
    }
    console.log('[ProjectPresetsService] > processPreset', processedPreset)
    return processedPreset
  }

  get defaultPreset () {
    return structuredClone({
      fields: presetFields,
      config: presetConfig,
      resources: presetResources
    })
  }

  openPresetGenerator (presetPartial = { fields: {}, config: {} }) {
    presetPartial = {
      fields: {
        definerType: {
          value: 'partner'
        },
        definer: {
          value: 'woocommerce-vip'
        },
        form: {
          value: 'woo-vip-general'
        },
        multiUseLink: {
          value: false,
        },
        expireDurationDays: {
          value: 7,
        },
        shortcode: {
          value: 'DAgFWd'
        },
        titlePrefix: {
          value: ''
        },
        introMessage: {
          value: ``
        },
        projectType: {
          value: 'other'
        },
        projectSubject: {
          value: 'other'
        },
        title: {
          value: ``
        },
        description: {
          value: ``
        },
        descriptionNotesEnable: {
          value: true
        },
        complexity: {
          value: null,
          hidden: true,
          locked: true
        },
        urgency: {
          value: null,
          hidden: true,
          locked: true
        }
      }
    }
    const preset = mergeObjects(this.defaultPreset, presetPartial)

    const tmp = {
      ...preset,
      ...this.extractPresetFieldValues(preset, [
        'definer',
        'definerType',
        'form',
        'shortcode',
        'multiUseLink',
        'expireDurationDays',
        'internalTrackingId'
      ])
    }

    // convert to snake case and stringify
    const presetJson = JSON.stringify(jsonInflector.transform(tmp, 'underscore'))

    this.ModalService.open(this.ModalService.generateConfig('success', {
      title: 'Project presets generator',
      body: `
        Currently this is editable only through running it locally. In future they can be exposed here in the modal.

        <cdbl-field-container>
          <textarea id="srcContentClipboard" type="text" name="presetJson" ng-value="$ctrl.modal.presetJson" readonly="readonly"></textarea>
        </cdbl-field-container>
      
        <cdbl-button class="button-link" ng-click="$ctrl.modal.copy2Clipboard()">Copy Link</cdbl-button>
      `,
      resolveButton: {
        label: 'Close'
      },
      presetJson: presetJson,
      copy2Clipboard: this.UtilService.copy2Clipboard
    }))
      .then(resolve => {
        console.log('modal resolve', resolve)
      })
      .catch(err => {
        console.log('modal reject', err)
      })
  }

  loadAllDatasets (resources = []) {
    const promises = []
    const resourcesMap = Object.entries(resources)
    resourcesMap.forEach(([resourceName, resourceConfig]) => {
      const config = { ...resourceConfig }
      config.url = `${this.Configuration.apiUrl}${config.url}`

      if (resourceName === 'taskProjectSubjects') {
        config.disableResponseDataInterceptor = true // disable JSON conversion so that BE snake case values can be used to find correct project subject
      }

      const p = this.$http(config)
        .then(response => [resourceName, { data: response.data }]) // wrap resposnse in data so it can be merged with resource method and url params
      promises.push(p)
    })

    return this.$q.all(promises).then(response => {
      return Object.fromEntries(response)
    })
  }
}
export default ProjectPresetsService
