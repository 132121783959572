import templateUrl from './presets-parent-edit.html'
import './presets-parent-edit.scss'
import { presetFields, fieldsLockedAllowEdit } from '../project-presets.config'

const ProjectPresetsParentEditComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectPresetsParentEditComponent {
    constructor ($timeout, $stateParams, $state, ProjectPresetsService, ModalService) {
      'ngInject'
      this._identify = 'ProjectPresetsParentEditComponent'
      this.$timeout = $timeout
      this.$stateParams = $stateParams
      this.$state = $state
      this.ProjectPresetsService = ProjectPresetsService
      this.ModalService = ModalService
      this.definerTypeList = [
        { id: 'partner', name: 'Partner' },
        { id: 'staff', name: 'Staff' },
        { id: 'expert', name: 'Experts' },
      ]
      this.isLoading = false
      this.isProcessing = false
      this.fieldsLockedAllowEdit = fieldsLockedAllowEdit
    }

    async $onInit () {
      this.isCreateNew = this.$stateParams.id === 'create'
      this.errorMessages = {
        'not_found': 'Form not found.',
        'PreferredExpertNotFoundError': 'The Codeable expert that was preselected for this form is no longer available. Please, contact Codeable support.',
      }

      // Load existing preset from BE or handle FE based default if creating from scratch
      this.loadParentPreset()
    }

    loadParentPreset () {
      this.error = null
      this.isLoading = true
      return this.ProjectPresetsService.loadParentPreset(this.$stateParams)
      // return this.ProjectPresetsService.loadParentPreset({ staff: 'default', form: 'default' }) // TODO: remove when BE ready
        .then(data => {
          this.preset = data
          return this.preset
        })
        .catch(err => {
          this.error = err
          throw err
        })
        .finally(() => {
          this.isLoading = false
          this.$timeout()
        })
    }

    createPreset () {
      this.isProcessing = true
      this.ProjectPresetsService.createParentPreset(this.preset)
        .then(response => {
          console.log(response)
          this.$state.go('presets.parents.list')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    updatePreset () {
      this.isProcessing = true
      this.ProjectPresetsService.updateParentPreset(this.preset)
        .then(response => {
          console.log(response)
          this.$state.go('presets.parents.list')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    onInitDefinerFormHandler ($event) {
      console.log('onInitDefinerFormHandler', $event)
      this.definerFormCtrl = $event.definerFormCtrl
    }

    openDefinerPreviewModal () {
      this.ModalService.open(this.ModalService.generateConfig('success', {
        title: 'DEFINER PREVIEW',
        body: `<cdbl-project-presets-definer-form preset="$ctrl.modal.preset" is-preview="true" class="align-left"></cdbl-project-presets-definer-form>`,
        resolveButton: {
          label: 'Close'
        },
        preset: this.preset
      }))
    }

    openClientPreviewModal () {
      this.ModalService.open(this.ModalService.generateConfig('success', {
        title: 'CUSTOMER PREVIEW',
        body: `<cdbl-project-presets-client-form preset="$ctrl.modal.preset" is-preview="true" class="align-left"></cdbl-project-presets-client-form>`,
        resolveButton: {
          label: 'Close'
        },
        preset: this.preset
      }))
    }

    toggleFieldsHidden (state = true) {
      Object.keys(this.preset.fields).forEach(key => {
        this.preset.fields[key].hidden = state
      })
    }
    toggleFieldsLocked (state = true) {
      Object.keys(this.preset.fields).forEach(key => {
        if (this.fieldsLockedAllowEdit.includes(key)) {
          this.preset.fields[key].locked = state
        }
      })
    }

    setDefault () {
      Object.keys(this.preset.fields).forEach(key => {
        if (presetFields[key]) {
          this.preset.fields[key].hidden = presetFields[key].hidden
          this.preset.fields[key].locked = presetFields[key].locked
        }
      })
    }
  }
}

export default ProjectPresetsParentEditComponent
